import da_DK from "./locales/da_DK.json";
import en_US from "./locales/en_US.json";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en_US",
  fallbackLocale: "en_US",
  messages: {
    da_DK,
    en_US,
  },
}));
